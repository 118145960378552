import React from 'react';
import bg from '../Images/background.jpg'
import {ArrowRightOutlined,ArrowLeftOutlined} from '@ant-design/icons'

export default function Vision(props) {
  return (
    <div>
      <div className="aboutHeaderImg">
        <img src={bg} className="bgImage" />
      </div>   

    <div className="aboutSubHeading">VISION</div>
    <p className="para">Lady Lilith is an oil painting by Dante Gabriel Rossetti first painted in 1866–1868 using his mistress Fanny Cornforth as the model, then altered in 1872–73 to show the face of Alexa Wilding.</p>
    <p className="para">The subject is Lilith, who was, according to ancient Judaic myth, "the first wife of Adam" and is associated with the seduction of men. She is shown as a "powerful and evil temptress" and as "an iconic, Amazon-like female with long, flowing hair.” In myth, Lilith is a powerful, threatening, sexual woman who resists domination by men. Thus, she has been considered a symbol of the feminist movement.</p>
    <p className="para">Graceland London’s vibrant, bold and iconic style perfectly encapsulates and recontextualises these meanings, exploding them on to the Now. Deftly, utilising cutting edge technology and the persistent problem of female exclusion in this sector, Grace has crafted a powerful, poetic and poignant artwork that stands head and shoulders above its contemporaries.</p>
    <p className="para">What initially began as a portraiture based PFP project, quickly escalated into something so much bigger. </p>
    <p className="para">Welcome To The World Of Graceland</p>
    <div className="BtnFooterContainer">
        <button onClick={()=>{props.setItemNo('2')}} className="btnFooterNext" >
          <ArrowLeftOutlined style={{color:'#fff',width:'40%',height:30, fontSize:30, marginTop:10,float:'left',textAlign:'left'}} />
          <div style={{width:'60%',height:50, float:'left'}}>
            <div className="text-color head1Pre">Previous-Team</div>
            <div className="text-color head2Pre">PREVIOUS</div>
          </div>
        </button>

        <button onClick={()=>{props.setItemNo('4')}} className="btnFooterNext" >
          <div style={{width:'40%',height:50, float:'left'}}>
            <div className="text-color head1">Next-Links</div>
            <div className="text-color head2">NEXT</div>
          </div>
          <ArrowRightOutlined style={{color:'#fff',width:'40%',height:30, fontSize:30, marginTop:10,float:'right',textAlign:'right'}} />
        </button>
      </div>
    </div>
  );
}
