import {useState} from 'react'
import { Menu,Row } from 'antd';
import { AppstoreOutlined, MailOutlined, SettingOutlined, FacebookFilled } from '@ant-design/icons';
import Navbar from './Components/Navbar';
import AboutGraceLand from './Components/AboutGraceLand';
import Links from './Components/Links';
import FAQ from './Components/FAQ'
import Genesis from './Components/Genesis'
import Lileth from './Components/Lileth';
import Utility from './Components/Utility';
import Staking from './Components/Staking';
import Vise from './Components/Vise';
import Vision from './Components/Vision';
import MeetTeam from './Components/MeetTeam'
import Graceland from './Images/GRACELANDLOGO.svg'
import PoweredByGraceland from './Images/PoweredByGraceland.svg'


function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}



const items = [
    getItem(<div className="intro">INTRODUCTION</div>, 'g1', null, [getItem('ABOUT GRACELAND LONDON', '1'), getItem('MEET THE TEAM', '2'), getItem('VISION', '3'), getItem('LINKS', '4'), getItem('FAQ', '5')], 'group'),
    getItem(<div className="ladyL">LADYLILETH</div>, 'g2', null, [getItem('GENESIS MINT', '6'), getItem('LILETH MINT', '7'), getItem('UTILITY', '8'), getItem('MOTEL STAKING POOLS', '9'), getItem('VI$E', '10')], 'group'),
];

const AboutUs = (props) => {

  var widthScreen = screen.width;
  const [itemNo, setItemNo] = useState('1')
  const onClick = (e) => {
    console.log('click ', e.key);
    setItemNo(e.key)
    if(widthScreen<=820)
    {
      setContainerWidth(0)
      setMenuWidth('98%')
      setIsmenuOpen(false)
    }
  };
  const [containerWidth,setContainerWidth] = useState(widthScreen>820 ?'25%': 0)
  const [menuWidth,setMenuWidth] = useState(widthScreen>820 ?'73%' : '98%')
  const [isMenuOpen,setIsmenuOpen] = useState(false)
  
  const menuToggle = () =>{
      if(isMenuOpen===false)
      {
        setContainerWidth('100%')
        setMenuWidth(0)
        setIsmenuOpen(true)
      }
      else
      {
        setContainerWidth(0)
        setMenuWidth('98%')
        setIsmenuOpen(false)
      }
  }

  return (
      <div style={{backgroundColor:'#1f1f1f',height:'auto'}} > 
        <Navbar setAbout={props.setAbout} menuToggle={menuToggle} />
        <Row>
        {((widthScreen<820 && isMenuOpen===true) || widthScreen>820) && <Menu
              theme={"dark"}
              onClick={onClick}
              style={{
                width: containerWidth
              }}
              className="menuContainer"
              defaultSelectedKeys={['1']}
              defaultOpenKeys={['1']}
              selectedKeys={itemNo}
              mode="inline"
              items={items}
          />}
          {((widthScreen<820 && isMenuOpen===false) || widthScreen>820) &&<div style={{width:menuWidth,marginLeft:'2%',marginTop:'2%',backgroundColor:'#1f1f1f'}}>
            
              {itemNo=='1' ?<AboutGraceLand setItemNo={setItemNo} />
              : itemNo== '2' ? <MeetTeam setItemNo={setItemNo} />
              : itemNo== '3' ? <Vision setItemNo={setItemNo} />
              : itemNo== '4' ? <Links setItemNo={setItemNo}/>
              : itemNo== '5' ? <FAQ setItemNo={setItemNo} />
              : itemNo== '6' ? <Genesis setItemNo={setItemNo} />
              : itemNo== '7' ? <Lileth  setItemNo={setItemNo} />
              : itemNo== '8' ? <Utility setItemNo={setItemNo} />
              : itemNo== '9' ? <Staking setItemNo={setItemNo} />
              : itemNo== '10' ? <Vise setItemNo={setItemNo} />
              : <div>Item4 </div>
              }
          </div>}
        </Row>
        <div className="footer">Powered By <img src={PoweredByGraceland} width={'30%'} /></div>
      </div>
  );
};

export default AboutUs;