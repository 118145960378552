import React from 'react';
import bg from '../Images/background.jpg'
import {ArrowRightOutlined,ArrowLeftOutlined} from '@ant-design/icons'
import StakingImg from '../Images/Staking.png'

export default function Staking(props) {
  var widthScreen = screen.width;
  return (
    <div>
      <div className="aboutHeaderImg">
        <img src={bg} className="bgImage" />
      </div>   

    <div className="aboutSubHeading">MOTEL STAKING POOLS</div>
    {widthScreen>820 ? <>
    <div className='row1'>
      <div style={{width:'100%',marginTop:'3%'}}>
    <p className="para">There are three main pools that holders will be able to stake their Lileth NFTs, each staking pool functions slightly differently and offers different rewards</p>
    <p className="para">The number of Lileths staked and thus the rewards and chances of winning will be displayed through the staking dashboard.</p>
    <br/>
    <p className="para">To start, there will be 3 pools: </p>
    <p className="para">1. The Jacuzzi: stake your Lileths here for a chance to win the opportunity to have your portrait made </p>
    <p className="para">2. The Steam Room : for the chance to pocket a Bet On Me NFT </p>
    <p className="para">3. The Golden Pool : is a utility token pool that will award 20 tokens daily to each and every Lileth staked. The tokens will be exchangeable for future drops and special one off items</p>
    <p className="para">* Each of your Lileth's can only be staked in one pool at a time</p>
    </div>
      <div style={{width:'40%'}}>
        <img src={StakingImg} style={{width:'100%',height:'auto'}} />
      </div>
    </div>
    </> :<>
    
    <div style={{width:'100%'}}>
      <div style={{width:'100%',marginTop:'3%'}}>
        <p className="para">There are three main pools that holders will be able to stake their Lileth NFTs, each staking pool functions slightly differently and offers different rewards</p>
        <p className="para">The number of Lileths staked and thus the rewards and chances of winning will be displayed through the staking dashboard.</p>
        <br/>
        <p className="para">To start, there will be 3 pools: </p>
        <p className="para">1. The Jacuzzi: stake your Lileths here for a chance to win the opportunity to have your portrait made </p>
        <p className="para">2. The Steam Room : for the chance to pocket a Bet On Me NFT </p>
        <p className="para">3. The Golden Pool : is a utility token pool that will award 20 tokens daily to each and every Lileth staked. The tokens will be exchangeable for future drops and special one off items</p>
        <p className="para">* Each of your Lileth's can only be staked in one pool at a time</p>
      </div>
      <div style={{width:'98%'}}>
        <img src={StakingImg} style={{width:'100%',height:'auto'}} />
      </div>
    </div>
    </>}
    <div className="BtnFooterContainer">
        <button onClick={()=>{props.setItemNo('8')}} className="btnFooterNext" >
          <ArrowLeftOutlined style={{color:'#fff',width:'40%',height:30, fontSize:30, marginTop:10,float:'left',textAlign:'left'}} />
          <div style={{width:'60%',height:50, float:'left'}}>
            <div className="text-color head1Pre">Previous-Utility</div>
            <div className="text-color head2Pre">PREVIOUS</div>
          </div>
        </button>

        <button onClick={()=>{props.setItemNo('10')}} className="btnFooterNext" >
          <div style={{width:'30%',height:50, float:'left'}}>
            <div className="text-color head1">Next-VI$E</div>
            <div className="text-color head2">NEXT</div>
          </div>
          <ArrowRightOutlined style={{color:'#fff',width:'40%',height:30, fontSize:30, marginTop:10,float:'right',textAlign:'right'}} />
        </button>
      </div>
    </div>
  );
}
