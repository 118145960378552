import React from 'react';
import bg from '../Images/background.jpg'
import {ArrowRightOutlined,ArrowLeftOutlined} from '@ant-design/icons'
import baverely from '../Images/Beverly.jpg'

export default function Genesis(props) {
  var widthScreen = screen.width;
  return (
    <div>
      <div className="aboutHeaderImg">
        <img src={bg} className="bgImage" />
      </div>   

    <div className="aboutSubHeading">GENESIS MINT</div>
   
    
    {widthScreen>820 ? <>
      <div className="genesisImageCon" style={{ alignItems: "center" }}>
      <div style={{width:'50%'}}>
        <p className="para4">
        A collection of 10,000 potential NFTs was created from Graceland London’s ‘Beverley Hells Mansion’ for the Genesis Boulevard mint. This was a great opportunity to collect a piece of Graceland’s artwork for free before the Lady Lileths were released. The mansion numbers received may hold perks for the Lady Lileth mint… and will also play a role in the future of the project.
        </p>
        <p className="para">
          First come, first served at the Beverly Hells Mansion
        </p>
        
      </div>
      <div style={{width:'50%'}}>
        <img src={baverely} style={{width:'100%',height:'auto'}} />
      </div>
      </div>
    </> :
      <div>
        <div style={{width:'100%'}}>
            <p className="para">
            A collection of 10,000 potential NFTs was created from Graceland London’s ‘Beverley Hells Mansion’ for the Genesis Boulevard mint. This was a great opportunity to collect a piece of Graceland’s artwork for free before the Lady Lileths were released. The mansion numbers received may hold perks for the Lady Lileth mint… and will also play a role in the future of the project.
            </p>
            <p className="para">
              First come, first served at the Beverly Hells Mansion
            </p>
        </div>
        <div style={{width:'98%',height:200}}>
            <img src={baverely} style={{width:'100%',height:'auto'}} />
        </div>
      </div>
    }
      
   

    <div className="BtnFooterContainer">
        <button onClick={()=>{props.setItemNo('5')}} className="btnFooterNext" >
          <ArrowLeftOutlined style={{color:'#fff',width:'40%',height:30, fontSize:30, marginTop:10,float:'left',textAlign:'left'}} />
          <div style={{width:'60%',height:50, float:'left'}}>
            <div className="text-color head1Pre">Previous-FAQ</div>
            <div className="text-color head2Pre">PREVIOUS</div>
          </div>
        </button>

        <button onClick={()=>{props.setItemNo('7')}} className="btnFooterNext" >
          <div className='genesisContainer'>
            <div className="text-color head1">Next-Lileth Mint</div>
            <div className="text-color head2">NEXT</div>
          </div>
          <ArrowRightOutlined style={{color:'#fff',width:'40%',height:30, fontSize:30, marginTop:10,float:'right',textAlign:'right'}} />
        </button>
      </div>
    </div>
  );
}
