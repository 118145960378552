import React from 'react';
import bg from '../Images/background.jpg'
import {ArrowRightOutlined,ArrowLeftOutlined} from '@ant-design/icons'
import VISE from '../Images/VISE-IMAGE.png'

export default function Vise(props) {
  var widthScreen = screen.width;
  return (
    <div>
      <div className="aboutHeaderImg">
        <img src={bg} className="bgImage" />
      </div>   

    <div className="aboutSubHeading">VI$E</div>
    {widthScreen>820 ? <>
    <div className='row1'>
      <div style={{width:'100%',marginTop:'3%'}}>
        <p className="para">VI$E is the utility token within the Graceland ecosystem.</p>
        <p className="para">Lady Lileth holders can stake each of their Lileths to receive 20 VI$E a day.</p>
        <p className="para">At a later stage there will be a shop launching where items can only be purchased using the VI$E token. This may offer advantages to holders over secondary market platforms. Not only this, some of these items may effect your Lileth, some may be exchangeable, some consumable, perhaps even some multipliable.. some may even be Graceland Londons physical artworks!</p>
        <p className="para">We have big plans for Vi$e.</p>
      </div>
      <div style={{width:'40%'}}>
        <img src={VISE} style={{width:'100%',height:'auto'}} />
      </div>
    </div>
    </> :<>
    <div style={{width:'100%'}}>
      <div style={{width:'100%',marginTop:'3%'}}>
        <p className="para">VI$E is the utility token within the Graceland ecosystem.</p>
        <p className="para">Lady Lileth holders can stake each of their Lileths to receive 20 VI$E a day.</p>
        <p className="para">At a later stage there will be a shop launching where items can only be purchased using the VI$E token. This may offer advantages to holders over secondary market platforms. Not only this, some of these items may effect your Lileth, some may be exchangeable, some consumable, perhaps even some multipliable.. some may even be Graceland Londons physical artworks!</p>
        <p className="para">We have big plans for Vi$e.</p>
      </div>
      <div style={{width:'80%',marginLeft:'10%',marginTop:'3%'}}>
        <img src={VISE} style={{width:'100%',height:'auto'}} />
      </div>
    </div>
    </>}
    <div className="BtnFooterContainer">
        <button onClick={()=>{props.setItemNo('9')}} className="btnFooterFull" >
          <ArrowLeftOutlined style={{color:'#fff',width:'40%',height:30, fontSize:30, marginTop:10,float:'left',textAlign:'left'}} />
          <div style={{width:'60%',height:50, float:'left'}}>
            <div className="text-color head1Pre">Previous-Staking</div>
            <div className="text-color head2Pre">PREVIOUS</div>
          </div>
        </button>
      </div>
    </div>
  );
}
