import React from 'react';
import Grace from '../Images/TeamMembers/Grace.jpg'
import Jason from '../Images/TeamMembers/Jason.jpg'
import Lewk from '../Images/TeamMembers/Lewk.jpg'
import Madi from '../Images/TeamMembers/Madi.jpg'
import member5 from '../Images/TeamMembers/member5.jpg'
import Twitter from '../Images/SocialTeamIcons/Twitter.svg'
import Linkedin from '../Images/SocialTeamIcons/Linkedin.svg'
import {ArrowRightOutlined,ArrowLeftOutlined} from '@ant-design/icons'
import {Row} from 'antd'

export default function MeetTeam(props) {
  return (
    <div>
      <div className="aboutSubHeading">MEET THE TEAM</div>

      <Row className="teamMemberContainer">
        
        <div className="TeamCard">
          <img src={Grace} className="teamImg" />
          <p className="parateam">Grace</p>
          <Row style={{width:'50%',marginLeft:'38%'}}>
            <a href="https://twitter.com/gracelandlondon" style={{width:'20%',marginRight:'5%'}}>
            <img src={Twitter} style={{width:'100%'}} />
            </a>
            
            <a href="https://uk.linkedin.com/in/gracemccallaart" style={{width:'20%'}}>
            <img src={Linkedin} style={{width:'100%'}} />
            </a>
          </Row>
        </div>

        <div className="TeamCard">
          <img src={Jason} className="teamImg" />
          <p className="parateam">Jason</p>
          <Row style={{width:'50%',marginLeft:'38%'}}>
            <a href="https://twitter.com/crypto_tej" style={{width:'20%',marginRight:'5%'}}>
            <img src={Twitter} style={{width:'100%'}} />
            </a>
            
            <a href="https://ie.linkedin.com/in/jason-teji" style={{width:'20%'}}>
            <img src={Linkedin} style={{width:'100%'}} />
            </a>
          </Row>
        </div>
        
        <div className="TeamCard">
          <img src={Lewk} className="teamImg" />
          <p className="parateam">Lewk</p>
          <Row style={{width:'50%',marginLeft:'38%'}}>
            <a href="https://twitter.com/TAndhope" style={{width:'20%',marginRight:'5%'}}>
            <img src={Twitter} style={{width:'100%'}} />
            </a>
            
            <a href="https://uk.linkedin.com/in/lewk-wilmshurst" style={{width:'20%'}}>
            <img src={Linkedin} style={{width:'100%'}} />
            </a>
          </Row>
        </div>
        
        <div className="TeamCard">
          <img src={Madi} className="teamImg" />
          <p className="parateam">Madi</p>
          <Row style={{width:'50%',marginLeft:'38%'}}>
            <a href="https://twitter.com/Multiseeed" style={{width:'20%',marginRight:'5%'}}>
            <img src={Twitter} style={{width:'100%'}} />
            </a>
            
            <a href="https://uk.linkedin.com/in/madi-wilmshurst" style={{width:'20%'}}>
            <img src={Linkedin} style={{width:'100%'}} />
            </a>
          </Row>
        </div>

        <div className="TeamCard">
          <img src={member5} className="teamImg" />
          <p className="parateam">Ross</p>
          <Row style={{width:'50%',marginLeft:'38%'}}>
            <a href="https://twitter.com/mc_calla1" style={{width:'20%',marginRight:'5%'}}>
            <img src={Twitter} style={{width:'100%'}} />
            </a>
            
            <a href="" style={{width:'20%'}}>
            <img src={Linkedin} style={{width:'100%'}} />
            </a>
          </Row>
        </div>

      </Row>   

    <div className="BtnFooterContainer">
        <button onClick={()=>{props.setItemNo('1')}} className="btnFooterNext" >
          <ArrowLeftOutlined className="prevBtn" style={{color:'#fff'}} />
          <div style={{width:'60%',height:50, float:'left'}}>
            <div className="text-color head1Pre">Previous-About</div>
            <div className="text-color head2Pre">PREVIOUS</div>
          </div>
        </button>

        <button onClick={()=>{props.setItemNo('3')}} className="btnFooterNext" >
          <div style={{width:'40%',height:50, float:'left'}}>
            <div className="text-color head1">Next-Vision</div>
            <div className="text-color head2">NEXT</div>
          </div>
          <ArrowRightOutlined className="nextBtn" style={{color:'#fff'}} />
        </button>
      </div>
    </div>
  );
}
