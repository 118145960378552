import React from 'react';
import bg from '../Images/background.jpg'
import {ArrowRightOutlined,ArrowLeftOutlined} from '@ant-design/icons'
import utilityImg from '../Images/Utility.png'

export default function Utility(props) {
  var widthScreen = screen.width;
  return (
    <div>
      <div className="aboutHeaderImg">
        <img src={bg} className="bgImage" />
      </div>   

    <div className="aboutSubHeading">UTILITY</div>
    <div className="aboutSubHeadingTwo">*Utility means use</div>
    {widthScreen>820 ? <>
    <div className='row1'>
      <div style={{width:'50%',marginTop:'3%'}}>
        <p className="para">Not only is each piece of this project an incredible work of art in its own right, it also offers a variety of utilities for the holders.</p>
        <p className="para">Holders will get access to the Graceland Discord where they can engage with the community and try their luck in the casino to win prizes.</p>
        <p className="para">Holders will also gain access to the staking dashboard where they can stake their NFTs into a variety of pools to receive different rewards.</p>
        </div>
      <div style={{width:'50%'}}>
        <img src={utilityImg} style={{width:'100%',height:'auto'}} />
      </div>
    </div>
    </> :<>
      <div style={{width:'100%'}}>
        <div style={{width:'100%',marginTop:'3%'}}>
          <p className="para">Not only is each piece of this project an incredible work of art in its own right, it also offers a variety of utilities for the holders.</p>
          <p className="para">Holders will get access to the Graceland Discord where they can engage with the community and try their luck in the casino to win prizes.</p>
          <p className="para">Holders will also gain access to the staking pools dashboard where they can stake their NFTs into a variety of pools to receive different rewards.</p>
          </div>
        <div style={{width:'100%', marginTop:'3%'}}>
          <img src={utilityImg} style={{width:'100%',height:'auto'}} />
        </div>
      </div>
    </>}
    <div className="BtnFooterContainer">
        <button onClick={()=>{props.setItemNo('7')}} className="btnFooterNext" >
          <ArrowLeftOutlined style={{color:'#fff',width:'40%',height:30, fontSize:30, marginTop:10,float:'left',textAlign:'left'}} />
          <div style={{width:'60%',height:50, float:'left'}}>
            <div className="text-color head1Pre">Previous-Lileth Mint</div>
            <div className="text-color head2Pre">PREVIOUS</div>
          </div>
        </button>

        <button onClick={()=>{props.setItemNo('9')}} className="btnFooterNext" >
          <div style={{width:'40%',height:50, float:'left'}}>
            <div className="text-color head1">Next-Staking</div>
            <div className="text-color head2">NEXT</div>
          </div>
          <ArrowRightOutlined style={{color:'#fff',width:'40%',height:30, fontSize:30, marginTop:10,float:'right',textAlign:'right'}} />
        </button>
      </div>
    </div>
  );
}
