import React from 'react';
import bg from '../Images/background.jpg'
import {ArrowRightOutlined} from '@ant-design/icons'
import Graceland from '../Images/GRACELANDLOGO.svg'
import MaddoxLogo from '../Images/MADDOX-GALLERY-Logo-Primary-London_white.png'

export default function AboutGraceLand(props) {
  var widthScreen = screen.width;
  return (
    <div>
      <div className="aboutHeaderImg">
        <img src={bg} className="bgImage" />
      </div>
    <div className="aboutSubHeading">ABOUT GRACELAND LONDON</div>
    <p className="para">Grace is best known as Graceland London and is represented by Maddox Gallery London.</p>
    <p className="para">Using brave, bold use of colour in her vibrant illustrations and paintings which depict the darker side of human nature, ego, mental health, vices, demons and consumption. Her art visualizes self obsession and displays self questioning quotes</p>
    <p className="para">Grace developed a love for art as a child, always favouring vivid use of colour. Her style is predominantly contemporary and humorous, but subtly influenced by the historical religious symbolism in Italian Renaissance and Flemish art. She explores characters in her artwork, depicting the emotions we feel and in turn the facades we display to the rest of the world. </p>
    <p className="para">Lady Lileth is that character</p>
    {widthScreen>820 ?
    <div className="AboutImageContainer" style={{display:'flex', justifyContent: 'space-evenly', width: '100%'}} >
      <img src={Graceland} width="40%" />
      <img src={MaddoxLogo} width="40%" />
    </div>
    : <div className="AboutImageContainer" style={{display:'flex', justifyContent: 'space-evenly', width: '100%'}} >
        <img src={Graceland} width="40%" />
        <img src={MaddoxLogo} width="40%" />
      </div>
    }

    <div className="BtnFooterContainer">
      <button onClick={()=>{props.setItemNo('2')}} className="btnFooterFull" >
        <div style={{width:'40%',height:50, float:'left'}}>
          <div className="text-color head1">Next-Meet the team</div>
          <div className="text-color head2">NEXT</div>
        </div>
        <ArrowRightOutlined style={{color:'#fff',width:'40%',height:30, fontSize:30, marginTop:10,float:'right',textAlign:'right'}} />
      </button>
    </div>
    </div>
  );
}
