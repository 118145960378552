import React from 'react';
import bg from '../Images/background.jpg'
import {ArrowRightOutlined,ArrowLeftOutlined} from '@ant-design/icons'

export default function FAQ(props) {
  return (
    <div>
      <div className="aboutHeaderImg">
        <img src={bg} className="bgImage" />
      </div>
    <div className="aboutSubHeading">FREQUENTLY ASKED QUESTIONS.</div>
    <div className="aboutSubHeading">What is the Lady Lileth project about?</div>
    <p className="para">Lileth is unafraid to acknowledge her darker side. She’s a powerful, threatening and sexual woman who resists domination from anyone. Deep down she’s a nice person, as long as you don’t cross her. Through the skilful application of Graceland’s artwork, this project encapsulates and recontextualises this narrative for the modern day.</p>
    <p className="para">The Lady Lileth NFTs unlock new paths to community access and social utility, as well as reward distribution for holders through staking mechanisms, and ultimately the first digital entry point into the world of Graceland. We’ve only scratched the surface.</p>

    <div className="aboutSubHeading">Where can I buy a Lady Lileth?</div>
    <p className="para">You can purchase a Lady Lileth directly from mint or else you can try your luck on the secondary markets such as OpenSea. Be careful and avoid the scams! Never say never, but it’s highly unlikely Lileth will try to slide into your DMs, if she does it will never be with a hard sell. Only purchase an NFT from verified links. If unsure, ask a fren.</p>

    <div className="aboutSubHeading">What are the commercial rights?</div>
    <p className="para">Holders have full commercial rights to use the specific NFT that they own.</p>


    <div className="BtnFooterContainer">
        <button onClick={()=>{props.setItemNo('4')}} className="btnFooterNext" >
          <ArrowLeftOutlined style={{color:'#fff',width:'40%',height:30, fontSize:30, marginTop:10,float:'left',textAlign:'left'}} />
          <div style={{width:'60%',height:50, float:'left'}}>
            <div className="text-color head1Pre">Previous-Links</div>
            <div className="text-color head2Pre">PREVIOUS</div>
          </div>
        </button>

        <button onClick={()=>{props.setItemNo('6')}} className="btnFooterNext" >
          <div className='genesisContainer'>
            <div className="text-color head1">Next-Genesis Mint</div>
            <div className="text-color head2">NEXT</div>
          </div>
          <ArrowRightOutlined style={{color:'#fff',width:'40%',height:30, fontSize:30, marginTop:10,float:'right',textAlign:'right'}} />
        </button>
      </div>
    </div>
  );
}
