import React from 'react';
import bg from '../Images/background.jpg'
import {ArrowRightOutlined,ArrowLeftOutlined} from '@ant-design/icons'

export default function Links(props) {
  var widthScreen = screen.width;
  return (
    <div>
      <div className="aboutHeaderImg">
        <img src={bg} className="bgImage" />
      </div>
      
      
      <div className="" style={{ alignItems: "center", display: "flex", flexDirection: 'row' }}>
        <div style={{width:'50%', display: "flex", flexDirection: 'column'}}>
          <div className="aboutSubHeading">Lady Lileth</div>
          <>
            <p className="para">Twitter: <a href="https://twitter.com/lady_lileth" className="para1">twitter.com/lady_lileth</a></p>
            <p className="para">Instagram: <a href="https://www.instagram.com/ladylileth/" className="para1">instagram.com/ladylileth/</a></p>
            <p className="para">Discord: <a href="https://discord.gg/aXSk2EREKm" className="para1">discord.gg/aXSk2EREKm</a></p>
            <p className="para">Website: <a href="https://ladylileth.com" className="para1">ladylileth.com</a></p>
          </>

          <div className="aboutSubHeading">PREVIOUS COLLECTIONS</div>

          <p className="para3">Genesis Boulevard:<a href="https://opensea.io/collection/genesis-boulevard" className="para1"> https://opensea.io/collection/genesis-boulevard</a></p>
          
        </div>

        <div style={{width:'50%', display: "flex", flexDirection: 'column'}}>
        <div className="aboutSubHeading">Graceland</div>
          <>
            <p className="para">Twitter: <a href="https://twitter.com/gracelandlondon" className="para1">twitter.com/gracelandlondon</a></p>
            <p className="para">Instagram: <a href="https://www.instagram.com/gracelandlondon" className="para1">instagram.com/gracelandlondon</a></p>
            <p className="para">Discord: <a href="https://discord.gg/aXSk2EREKm" className="para1">discord.gg/aXSk2EREKm</a></p>
            <p className="para">Website: <a href=" https://gracelandlondon.com" className="para1">gracelandlondon.com</a></p>
          </>

          <div className="aboutSubHeading">PREVIOUS COLLECTIONS</div>

          <p className="para3">Graceland London:<a href="https://opensea.io/collection/graceland-london" className="para1"> https://opensea.io/collection/graceland-london</a></p>
       
        </div>
      </div>

      <div className="BtnFooterContainer">
        <button onClick={()=>{props.setItemNo('3')}} className="btnFooterNext" >
          <ArrowLeftOutlined style={{color:'#fff',width:'40%',height:30, fontSize:30, marginTop:10,float:'left',textAlign:'left'}} />
          <div style={{width:'60%',height:50, float:'left'}}>
            <div className="text-color head1Pre">Previous-Vision</div>
            <div className="text-color head2Pre">PREVIOUS</div>
          </div>
        </button>

        <button onClick={()=>{props.setItemNo('5')}} className="btnFooterNext" >
          <div style={{width:'40%',height:50, float:'left'}}>
            <div className="text-color head1">Next-FAQ</div>
            <div className="text-color head2">NEXT</div>
          </div>
          <ArrowRightOutlined style={{color:'#fff',width:'40%',height:30, fontSize:30, marginTop:10,float:'right',textAlign:'right'}} />
        </button>
      </div>
    </div>
  );
}
