import React, { useState,useEffect} from 'react';
import {NavLink,useNavigate} from 'react-router-dom';
import Logo from '../Images/logo.png'
import { Layout, Menu } from 'antd';
const {Header} = Layout;
import {Row} from 'antd'
import {MenuOutlined} from '@ant-design/icons'


function Navbar(props) {

    var widthScreen = screen.width;
    return( 
        <div className="Navbar">
            <button onClick={()=>props.setAbout(false)} className="logo">
                <img src={Logo} style={{width:160,position:'absolute',top:-30}} />
            </button>
            {widthScreen<820 && <MenuOutlined style={{color:'#fff', fontSize:35,position:'absolute',top:23, right:'5%'}} onClick={()=>props.menuToggle()} />}
        </div>
    )
}

export default Navbar;