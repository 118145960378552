import './App.css';
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./globalStyles";
import background from './Images/background.jpg';
import Clouds from './Images/CLOUDS.png';
import Plane from './Images/PLANE.png';
import leaves from './Images/leaves.png';
import money from './Images/money.png';
import mint from './Images/Buttons/mint.png'
import mintInactive from './Images/Buttons/mintInactive.png'
import mintActive from './Images/Buttons/mintActive.png'
import treecharacter from './Images/treecharacter.png'
import tree from './Images/tree.png'
import discord from './Images/Buttons/Discord.svg'
import fb from './Images/Buttons/Fb.svg'
import instagram from './Images/Buttons/IG.svg'
import Dashboard from './Images/Buttons/Dashboard.svg'
import Info from './Images/Buttons/Info.svg'
import twitter from './Images/Buttons/Twitter.svg'
import monaLisa from './Images/mona-lisa.png'
import GreenChar from './Images/green-character.png'
import orangeCharacter from './Images/orangeCharacter.png'
import Modal from 'react-modal';
import AboutUs from './AboutUs'
import Navbar from './Components/Navbar'
import 'antd/dist/antd.css';
import { SocialIcon } from 'react-social-icons';
import Iframe from 'react-iframe'
import BGMobile from './Images/mobileAssets/bg.jpg'
import GreenSlimeMobile from './Images/mobileAssets/GreenSlimeMobile.svg'
import CrimeScene from './Images/mobileAssets/CrimeSceneHere.svg'
import moremore from './Images/mobileAssets/MOREMOREMORE.svg'
import Mintbtnmobile from './Images/mobileAssets/Mintbtnmobile.png'


function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [claimed, setClaimed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [about, setAbout] = useState(false)
  const [feedback, setFeedback] = useState(`Click buy to mint your NFT.`);
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  })

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    console.log("config")
    console.log(config)
    if(Number(data.totalSupply)<=777){
      config.DISPLAY_COST = 0
    }
    SET_CONFIG(config);
    window.scrollTo(window.screen.width/8,window.screen.height/4)
  };

  var widthScreen = screen.width;

  useEffect(() => {
    getConfig();    
  }, []);

  useEffect(()=>{
    if(about===false)
    {
      window.scrollTo(window.screen.width/8,window.screen.height/4)
    }
  },[about])
  useEffect(() => {
    getData();
  }, [blockchain.account]);

  const [mousePosition, setMousePosition] = useState({
    x:null,
    y:null,
    movementX: null,
    movementX: null
  });
  
  useEffect(()=>{
    function handle(e)
    {
      setMousePosition({
        x: e.pageX,
        y: e.pageY,
        movementX: e.movementX,
        movementY: e.movementY
      })
      
      
    }

    document.addEventListener("mousemove",handle);
    // console.log(mousePosition)
    return () => document.removeEventListener("mousemove",handle)
    
  })

  const [btnImg, setBtnImg]= useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [modalIsOpenLink, setModalIsOpenLink] = useState(false)

  function openModal() {
    window.location.href = 'https://dashboard.ladylileth.com/mint';
    return null;
  }

  function afterOpenModal() {
    window.scrollTo(80,0)
  }

  function closeModal() {
    setModalIsOpen(false);
  }

  function afterOpenModalLink() {
    window.scrollTo(80,0)
  }

  function closeModalLink() {
    setModalIsOpenLink(false);
  }

  var customStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.75)'
    },
    content: {
      top: widthScreen>820 ?'50%' :'0%',
      left: widthScreen>820 ?'50%' :'0%',
      right: 'auto',
      bottom: 'auto',
      marginRight: widthScreen>820 ?'-50%' :'0%',
      transform: widthScreen>820 ? 'translate(-50%, -50%)' :'translate(0%, 0%)',
      backgroundColor:'#000',
      borderColor:'#000',
      height:450,
      width:450
    }
  };

  const menuToggle = () =>{
    setAbout(true)
  }

  var customStyles1 = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.75)'
    },
    content: {
      top: widthScreen>820 ?'50%' :'0%',
      left: widthScreen>820 ?'50%' :'0%',
      right: 'auto',
      bottom: 'auto',
      marginRight: widthScreen>820 ?'-50%' :'0%',
      transform: widthScreen>820 ? 'translate(-50%, -50%)' :'translate(0%, 0%)',
      backgroundColor:'rgba(0, 0, 0, 0.75)',
      borderColor:'rgba(0, 0, 0, 0.75)',
      height:'auto',
      width: widthScreen>820 ? '30%': '100%'
    }
  };

  if(!loading && !about)
  {
  return (
    <>
      
      {widthScreen>820 ? <div id="wrapper">
      
      <img id="wrapbg" className="" src={background} alt="" />
      
      <img id="layer2" className="parallax" src={Clouds} 
      style={{ transform:`translateX(-${mousePosition.x*0.02}px)`}} 
      alt="" />
      <img id="layer2" className="parallax" src={Plane} 
      style={{ transform:`translatey(-${mousePosition.y*0.02}px)`}} 
      alt="" />
      <img id="layer2" className="parallax" src={treecharacter} alt="" />
      <img id="layer2" className="parallax" src={tree}
      style={{ transform:`translateX(-${mousePosition.x*0.01}px) translateY(-${mousePosition.y*0.01}px)`}}
      alt="" />

      <img id="layer1" className="parallax" src={leaves} 
      style={{ transform:`translateX(-${mousePosition.x*0.05}px)`}} 
      alt="" />
      <img id="layer4" className="parallax" src={orangeCharacter}
      alt="" />
      <img id="layer4" className="parallax" src={money} 
       style={{ transform:`translateY(-${mousePosition.y*0.03}px) translateX(-${mousePosition.x*0.03}px)`}} 
      alt="" />

      <img id="layer2" className="parallax" src={GreenChar} 
       
      alt="" 
      />

      <img id="layer2" className="parallax" src={monaLisa} alt=""
      style={{ transform:`translateX(-${mousePosition.x*0.02}px)`}}
      />

      <button className="mintBtn"  
        onClick={()=>openModal()}
        // style={{ transform:`translateY(-${mousePosition.y*0.25}px)`}}
        >
          {btnImg!=='' && <img src={mintActive} style={{width:'100%'}} />}
          <img onMouseEnter={()=>{setBtnImg(mintActive)}} onMouseLeave={()=>{setBtnImg('')}} src={mint} style={{width:'100%', position:'absolute', top:-10, right:0,bottom:0, left:15}} />
          {/* <img onMouseEnter={()=>{setBtnImg(mintActive)}} onMouseLeave={()=>{setBtnImg(mintInactive)}} src={mint} style={{width:400, position:'absolute', top:160, right:0,bottom:0, left:140}} /> */}
        </button>
      
      <div className="right-side">
        <div style={{position:'fixed', width:'100%'}} >

          <button className="aboutBtn"  
            onClick={() => {
              window.open('https://dashboard.ladylileth.com/')
            }}
          >
            <img src={Dashboard} style={{width:'100%'}} />
          </button>
          
          <button className="aboutBtn"  
          onClick={() => {
            window.open('https://resources.ladylileth.com')
          }}
          >
            <img src={Info} style={{width:'100%'}} />
          </button>

          <button className="aboutBtn"  
          onClick={() => {
            window.open('https://discord.gg/aXSk2EREKm')
          }}
          >
            <img src={discord} style={{width:'100%'}} />
          </button>

          <button className="aboutBtn"
            onClick={() => {
              window.open('https://www.instagram.com/gracelandlondon')
            }}
          >
            <img src={instagram} style={{width:'100%'}} />
          </button>

          <button className="aboutBtn"
            onClick={() => {
              window.open('https://twitter.com/gracelandlondon')
            }}
          >
            <img src={twitter} style={{width:'100%'}} />
          </button>    

      </div>
      </div>
      
    </div>
    : <div className='mobileContainer'>
      <Navbar setAbout={setAbout} menuToggle={menuToggle} />
      <img src={BGMobile} className="mobileBg" />
      <img src={GreenSlimeMobile} className="mobileBg" />
      <p className='mobText'>DON'T MISS THE GENESIS MINT</p>
      <p className='mobText1'>FIRST 777 MINT FREE</p>
      <button 
      className="mintBtnMob" 
      onClick={()=>openModal()}
      >
        <img src={Mintbtnmobile} style={{width:'100%',height:'100%'}} />
      </button>
      <img src={CrimeScene} className="mobileBg" />
      <img src={moremore} className="moremore" />
      <div className="right-side-mob">
        <div style={{width:'100%'}} >
          <button className="aboutBtnMob"  
            onClick={() => {
              setAbout(true)
            }}
            >
              <img src={Info} style={{width:'100%'}} />
            </button>

            <button className="aboutBtnMob"  
            onClick={() => {
              window.open('https://discord.gg/aXSk2EREKm')
            }}
            >
              <img src={discord} style={{width:'100%'}} />
            </button>

            <button className="aboutBtnMob"
              onClick={() => {
                window.open('https://www.instagram.com/gracelandlondon')
              }}
            >
              <img src={instagram} style={{width:'100%'}} />
            </button>

            <button className="aboutBtnMob"
              onClick={() => {
                window.open('https://twitter.com/gracelandlondon')
              }}
            >
              <img src={twitter} style={{width:'100%'}} />
            </button>    

      </div>
      </div>
      </div>
      }
    </>
  );
  }

  else if(!loading && about)
  {
    return(
      <AboutUs about={about} setAbout={setAbout} />
    )
  }
  else
  {
    return(
      <div style={{color:'#fff', fontSize:20, fontWeight:'bold'}}>Loading ......</div>
    )
  }
}

export default App;

// 
