import React from 'react';
import bg from '../Images/background.jpg'
import {ArrowRightOutlined,ArrowLeftOutlined} from '@ant-design/icons'
import portrait1 from '../Images/Portrait/1.jpg'
import portrait2 from '../Images/Portrait/2.jpg'
import {Row} from 'antd';
import portrait3 from '../Images/Portrait/3.jpg'

export default function Lileth(props) {
  var widthScreen = screen.width;
  return (
    <div>
      <div className="aboutHeaderImg">
        <img src={bg} className="bgImage" />
      </div>   

    <div className="aboutSubHeading">LILETH MINT</div>
    
    <Row className="lilethContainer">

      <div className="lilethSubContainer">
        <p className="para2">A Generative Profile picture NFT</p>
        <p className="para2">A collection of 7777 unique NFT portraits of Graceland London’s Lady Lilith will be released in 25th October 2022 on the Ethereum Blockchain.</p>
        <p className="para2">Whilst looking amazing, these NFTs will also function as membership tokens to a community that offers inbuilt rewards from the very birth of the project.  </p>
      </div>

      <div className="portraitContainer">
        <img src={portrait1} className="lilethImageW" style={{marginLeft:widthScreen>820 && '3%',marginRight:widthScreen>820 && '2%'}} />
        <img src={portrait2} className="lilethImageW" style={{marginRight:widthScreen>820 && '2%'}} />
        <img src={portrait3} className="lilethImageW"  />
      </div>
    </Row>

    <div className="BtnFooterContainer">
        <button onClick={()=>{props.setItemNo('6')}} className="btnFooterNext" >
          <ArrowLeftOutlined style={{color:'#fff',width:'40%',height:30, fontSize:30, marginTop:10,float:'left',textAlign:'left'}} />
          <div style={{width:'60%',height:50, float:'left'}}>
            <div className="text-color head1Pre">Previous-Genesis</div>
            <div className="text-color head2Pre">PREVIOUS</div>
          </div>
        </button>

        <button onClick={()=>{props.setItemNo('8')}} className="btnFooterNext" >
          <div style={{width:'40%',height:50, float:'left'}}>
            <div className="text-color head1">Next-Utility</div>
            <div className="text-color head2">NEXT</div>
          </div>
          <ArrowRightOutlined style={{color:'#fff',width:'40%',height:30, fontSize:30, marginTop:10,float:'right',textAlign:'right'}} />
        </button>
      </div>
    </div>
  );
}
